'use client';
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import COLORS from './constants/colors';

const theme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: COLORS.white,
          borderRadius: '20px',
          borderWidth: '0px',
          border: '0px solid',
          backgroundColor: COLORS.slate[900],
          '&.Mui-disabled:not(.Mui-selected)': {
            color: COLORS.neutral[400],
            backgroundColor: COLORS.slate[950],
          },
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          color: COLORS.white,
          backgroundColor: COLORS.slate[700],
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          color: COLORS.white,
        },
        switchViewButton: {
          color: COLORS.white,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: COLORS.white,
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          color: COLORS.white,
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red['500'],
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;
